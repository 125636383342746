// extracted by mini-css-extract-plugin
export var sectionBg = "section-hero-module--sectionBg--5ZTFj";
export var ray = "section-hero-module--ray--UdVrT";
export var lightField = "section-hero-module--light-field--gkFiz";
export var auroraSmall = "section-hero-module--aurora-small--2eHJh";
export var shiftSmall = "section-hero-module--shift-small--ndinX";
export var auroraMedium = "section-hero-module--aurora-medium--t-cOL";
export var shiftMedium = "section-hero-module--shift-medium--z6s7w";
export var auroraLarge = "section-hero-module--aurora-large--SqpAH";
export var shiftLarge = "section-hero-module--shift-large--9fQsP";
export var swoopArrow = "section-hero-module--swoopArrow--VDmgL";
export var float = "section-hero-module--float--TylQV";
export var twinkle = "section-hero-module--twinkle--n1rJW";